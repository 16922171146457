<template>
  <aside class="aside-bottom">
    <div>
      <div class="aside-container">
        <div class="row box-bottom box-chat">
          <div class="col-sm-12">
            <a class="politica-privacidade" href="https://wa.me/5516981354817">
              <img
                alt="ícone email"
                class="icon-email open-text"
                width="36px"
                height="36px"
                src="../../assets/icons/whats-icon-green.svg"
              />
            </a>
          </div>
          <div class="bar-chat hidden-xs hidden-sm fixed">
            <button class="btn-chat openModal" type="button" @click="send()">
              <img
                alt="ícone email"
                class="icon-email open-text"
                width="36px"
                height="36px"
                src="../../assets/icons/whats-icon.svg"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </aside>
</template>
<script>
import sendMessage from "../../mixins/sendMessage";
export default {
  mixins: [sendMessage]
};
</script>

const sendMessage = {
  methods: {
    send() {
      window.open(
        "https://api.whatsapp.com/send?phone=5516981354817&text=Ol%C3%A1%20Marcius%20meu%20nome%20%C3%A9"
      );
    }
  }
};

export default sendMessage;
